
import { Watch, Mixins, Component, ProvideReactive } from "vue-property-decorator";

import CustomSelfList from "./components/customSelfList.vue";
import CustomSelfCharge from "./components/customSelfCharge.vue";
import CustomSelfExpense from "./components/customSelfExpense.vue";


@Component({
  inject: [],
  components: { CustomSelfList, CustomSelfCharge, CustomSelfExpense }
})
export default class CustomSelfIndex extends Mixins() {
    @ProvideReactive() currentTab = "1";
    @ProvideReactive() userPhone = ""

    currentTabChange() {
      this.userPhone = "";
    }

    clearUserPhone() {
      this.userPhone = "";
    }

    syncCurrentTab({ currentTab, phone }) {
      this.currentTab = currentTab;
      this.userPhone = phone;
    }
}

