
import {
    Watch,
    Prop,
    Mixins,
    Component,
    Ref,
    InjectReactive
} from "vue-property-decorator";

import ETable from "@/components/ETable.vue";
import tablePagination from "@/mixins/tablePagination";
import loading from "@/mixins/loading";
import Resize from "@/mixins/resize";

import { getCustomShareCombo, getGroupComboDeviceList } from "@/api/shareGroup";

@Component({
    components: {
        ETable
    }
})
export default class CustomSelfCharge extends Mixins(tablePagination, loading, Resize) {
    [x: string]: any;

    @InjectReactive() currentTab !: string;
    @InjectReactive() userPhone !: string;

    tabListSurplusHeight = 100;

    queryForm = {
        phone: "",
        userName: "",
        deviceClassId: "",
        groupName: "",
        comboName: "",
        orderNo: "",
    };

    itemTotal = 0;
    tableData = [];

    deviceTypeListOptions = [];


    columns = [{
            label: "用户ID",
            prop: "userId"
        },
        {
            label: "用户头像",
            prop: "icon"
        },
        {
            label: "用户昵称",
            prop: "userName"
        },
        {
            label: "用户手机号",
            prop: "phone"
        },
        {
            label: "小组名称",
            prop: "groupName"
        },
        {
            label: "套餐名称",
            prop: "comboName"
        },
        {
            label: "套餐次数",
            prop: "comboNum"
        },

        {
            label: "套餐适用设备型号",
            prop: "",
            formatter: (row) => row.deviceTypeIds?.map(el => el.deviceTypeName)?.join("、")
        },
        {
            label: "添加方式",
            prop: "",
            formatter: (row) => row.type == 0 ? "人工" : "商城",
        },
        {
            label: "添加人",
            prop: "modifiedBy"
        },
        {
            label: "添加时间",
            prop: "modifiedTime"
        },
        {
            label: "订单号",
            prop: "orderNo"
        },
        {
            label: "备注",
            prop: ""
        },
    ];

    formVisible = false;

    detailVisible = false;

    form = {};
    detailInfo = {};

    mounted() {
        this.windowResize(1000);
        this.getGroupComboDeviceList();
        this.getData();
    }

    @Watch("currentTab")
    handleSearch() {
        if (this.currentTab !== "2") return;
        this.queryForm.phone = this.userPhone || this.queryForm.phone;
        this.$emit("clearUserPhone")
        this.resetCurrent();
        this.getData();
    }

    getData() {
        this.showLoading();
        const params = {
            ...this.queryForm,
            pageNum: this.currentPage,
            pageSize: this.pageSize,
        }
        getCustomShareCombo(params).then(res => {
            this.tableData = res.data.list;
            this.itemTotal = res.data.total;
        }).finally(() => {
            this.hideLoading();
        })
    }

    add() {
        this.formVisible = true;
    }

    detail(row) {
        this.detailVisible = true;
    }
    relationDevice(row) {
        this.relationDeviceVisible = true;
    }
    managerCombo(row) {
        this.comboVisible = true;
    }

    getGroupComboDeviceList() {
        getGroupComboDeviceList().then((res) => {
            this.deviceTypeListOptions = res.data;
        })
    }

}
