
import {
    Component,
    Mixins,
    PropSync,
    Vue,
    Watch,
    Prop
} from "vue-property-decorator";
import ETable from "@/components/ETable.vue";

import Resize from "@/mixins/resize";
import Loading from "@/mixins/loading";
import TablePagination from "@/mixins/tablePagination";

import { getShareComboBuyUser } from "@/api/shareGroup";

@Component({
    components: { ETable },
})
export default class CustomSelfDetail extends Mixins(Loading, Resize, TablePagination) {
    @PropSync("detailVisible") visible: boolean;
    @Prop() modelDetail !: any;

    columns = [
        { label: "小组名称", prop: "groupName" },
        { label: "套餐名称", prop: "comboName" },
        { label: "套餐适用设备型号", width: 250, prop: "", formatter: (row) => row.deviceTypeIds?.map(el => el.deviceTypeName)?.join("、") },
        { label: "总充值次数", prop: "comboNum" },
        { label: "总消费次数", prop: "", formatter: (row) => row.comboNum - row.remainingNum },
        { label: "总剩余次数", prop: "remainingNum" },
    ]

    tableData = []

    mounted() {
        this.setTableMaxheight();
    }

    handleClose() {
        this.visible = false;
    }

    @Watch("visible", { immediate: true })
    getShareComboBuyUser() {
        if (!this.visible) return;
        this.resetCurrent();
        this.getData();
    }

    getData() {
        this.showLoading();
        const params = {
            userId: this.modelDetail.userId,
            pageNum: this.currentPage,
            pageSize: this.pageSize,
        }
        getShareComboBuyUser(params).then(res => {
            this.tableData = res.data.list;
            this.itemTotal = res.data.total;
        }).finally(() => {
            this.hideLoading();
        })
    }
}

