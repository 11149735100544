
import { Component, Vue } from 'vue-property-decorator'

import {
    groupShareList
} from "@/api/shareGroup"

import {
    debounce
} from "lodash"
@Component
export default class GetShareGroupByRemote extends Vue {
    groupLoading = false;
    shareGroupList = [];
    
    getShareGroupRemote = debounce(function (str) {
        this.groupLoading = true;
        groupShareList({
                name: str,
                pageNum: 1,
                pageSize: 500
            })
            .then((res) => {
                this.groupLoading = false;
                this.shareGroupList = res.data.list || [];
            })
            .catch((err) => {});
    })

    getShareGroupList(str) {
        if (typeof str == "object") {
            str = "";
        }
        this.getShareGroupRemote(str);
    }
}

