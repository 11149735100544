
import {
    Component,
    PropSync,
    Ref, Mixins
} from "vue-property-decorator";

import GetUserByRemote from "@/mixins/getUserByRemote";
import GetShareComboByRemote from "../mixins/getShareComboByRemote";
import GetShareGroupByRemote from "../mixins/getShareGroupList";

import { addShareComboUserCount } from "@/api/shareGroup";

@Component({
    components: {},
})
export default class AddCustomSelf extends Mixins(GetUserByRemote, GetShareComboByRemote, GetShareGroupByRemote) {
    @Ref("form") formRef: VForm;
    @PropSync("formVisible") visible: boolean;
    @PropSync("modelForm") formData: any;

    saveLoading = false;

    form = {
        userId: "",
        phone: "",
        nickname: "",
        groupId: "",
        comboId: "",
        remark: ""
    }

    formRules = {
        phone: [{
            required: true,
            message: "请输入用户手机号",
            trigger: "change"
        }],
        groupId: [{
            required: true,
            message: "请选择小组",
            trigger: "change"
        }],
        comboId: [{
            required: true,
            message: "请选择套餐",
            trigger: "change"
        }],
    }

    handleClose() {
        this.formRef?.resetFields();
        this.visible = false;
    }

    cancel() {
        this.handleClose();
    }

    confirm() {
        this.formRef?.validate().then(() => {
            
            const params = {
                ...this.form
            }
            console.log("params", params)
            addShareComboUserCount(params).then(() => {
                this.$message.success("操作成功");
                this.handleClose();
                this.$emit('syncCustomList');
            })
        }).catch((err) => {
            console.log(err)
        })
    }

    setCurUser($event) {
        const user = this.allUserList.find(item => item.phone == $event);
        this.form.userId = user.id;
        this.form.nickname = user.nickname;
    }

    setShareCombo($event) {

    }
}
