
import {
    Watch,
    Prop,
    Mixins,
    Component,
    Ref,
    InjectReactive
} from "vue-property-decorator";

import ETable from "@/components/ETable.vue";
import tablePagination from "@/mixins/tablePagination";
import loading from "@/mixins/loading";
import Resize from "@/mixins/resize";

import { getCustomShareComboExpense } from "@/api/shareGroup";

@Component({
    components: {
        ETable
    }
})
export default class CustomSelfExpense extends Mixins(tablePagination, loading, Resize) {
    [x: string]: any;

    @InjectReactive() currentTab !: string;
    @InjectReactive() userPhone !: string;

    tabListSurplusHeight = 100;

    queryForm = {
        phone: "",
        nickname: "",
        deviceClassId: "",
        groupName: "",
        comboName: "",
        orderNo: "",
        deviceName: "",
        deviceMessageCode: "",
        storeName: ""
    };

    itemTotal = 0;
    tableData = [];

    deviceCategoryOptions=[];

    columns = [{
            label: "用户ID",
            prop: "userMemberId"
        },
        {
            label: "用户头像",
            prop: "userIcon"
        },
        {
            label: "用户昵称",
            prop: "nickname"
        },
        {
            label: "用户手机号",
            prop: "userPhone"
        },
        {
            label: "设备分类",
            prop: "deviceClassName"
        },
        {
            label: "设备型号",
            prop: "deviceName"
        },
        {
            label: "设备编码",
            prop: "deviceMessageCode"
        },
        {
            label: "设备共享小组名称",
            prop: "groupName"
        },
        {
            label: "消费时间",
            prop: "createdTime"
        },
        {
            label: "消费门店",
            prop: "storeName"
        },
    ];

    formVisible = false;

    detailVisible = false;

    form = {};
    detailInfo = {};

    mounted() {
        this.windowResize(400);
        this.getData();
    }

    @Watch("currentTab")
    handleSearch() {
        if (this.currentTab !== "3") return;
        this.queryForm.phone = this.userPhone || this.queryForm.phone;
        this.$emit("clearUserPhone")
        this.resetCurrent();
        this.getData();
    }

    getData() {
        this.showLoading();
        const params = {
            ...this.queryForm,
            pageNum: this.currentPage,
            pageSize: this.pageSize,
        }
        getCustomShareComboExpense(params).then(res => {
            this.tableData = res.data.list;
            this.itemTotal = res.data.total;
        }).finally(() => {
            this.hideLoading();
        })
    }

    add() {
        this.formVisible = true;
    }

    detail(row) {
        this.detailVisible = true;
    }
    relationDevice(row) {
        this.relationDeviceVisible = true;
    }
    managerCombo(row) {
        this.comboVisible = true;
    }

    deviceClassNameChange() {
        
    }

}
